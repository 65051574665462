import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home'),
    },
    {
      path: '/privacy-policies/discalculate',
      name: 'privacy-policies-discalculate',
      component: () => import('@/views/privacy-policies/discalculate'),
      meta: {
        hideSidebar: true,
      },
    },
    {
      path: '/privacy-policies/nameify',
      name: 'privacy-policies-nameify',
      component: () => import('@/views/privacy-policies/nameify'),
      meta: {
        hideSidebar: true,
      },
    },
    {
      path: '/leasing-calculator',
      name: 'leasing calculator',
      component: () => import('@/views/leasing-calculator'),
      meta: {
        hideSidebar: true,
      },
    },
  ],
})

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require('vue-analytics').default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development',
    },
  })
}

export default router
