<template>
  <v-app>
    <core-drawer v-if="!$route.meta.hideSidebar" />

    <v-main class="overflow-hidden">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  export default {
    components: {
      CoreDrawer: () => import('@/components/core/Drawer'),
    },
  }
</script>

<style>
  .text-decoration-none {
    text-decoration: none;
  }
</style>
