import Vue from 'vue'
import './plugins/base'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

Vue.config.productionTip = false

/**
 * VeeValidate setup
 */
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

extend('required', {
  ...required,
  message: 'This field is required.',
})
extend('email', {
  ...email,
  message: 'Please enter a valid email.',
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
